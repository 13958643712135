import React, {useCallback, useState} from 'react';
import styled from "styled-components";
import {useDrop} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';
import isEmpty from 'lodash/isEmpty';
import Table from "./Table";
import image from "./assets/jtable.png"

const CenteredDiv = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Background = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledText = styled.div`
  padding-top: 50px;
  font-size: x-large;
  color: ${props => props.error ? 'red' : 'black'};
`;

const StyledImage = styled.img`
  width: 150px;
`;

const TextContent = ({error, text}) => <CenteredDiv error={error}>
    <StyledImage src={image} alt="jtable"/>
    <StyledText>{text}</StyledText>
</CenteredDiv>;

const JTableDropContainer = () => {
    const [state, setState] = useState({});

    const handleFileDrop = useCallback((item, monitor) => {
        if (monitor) {
            const {files} = monitor.getItem();
            if (isEmpty(files)) {
                return
            }
            const file = files[0];
            const reader = new FileReader();
            setState({...state, isLoading: true});
            reader.onload = async (e) => {
                const text = (e.target.result);
                try {
                    let {header, rows, Header, Rows} = JSON.parse(text);
                    rows = rows ?? Rows;
                    header = header ?? Header;
                    if (isEmpty(header) || isEmpty(rows)) {
                        throw new Error("invalid json");
                    }
                    setState({
                        fileName: file.name,
                        header,
                        rows
                    });
                }
                catch (ex) {
                    console.error(ex);
                    setState({
                        fileName: file.name,
                        error: ex.message
                    });
                }
            };
            reader.readAsText(file)
        }
    }, [setState, state]);

    const [, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop(item, monitor) {
            handleFileDrop(item, monitor)
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    return (
        <Background ref={drop}>
            {
                state.error ?
                    <TextContent error text={`ERROR: ${state.error}`}/>
                    : state.header
                    ? <Table header={state.header} rows={state.rows} fileName={state.fileName}/>
                    : <TextContent text={state.isLoading ? "Loading..." : "To start, drag n' drop a JTable json file"}/>
            }
        </Background>
    );
};

export default JTableDropContainer;
