import React from 'react'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {Tab, TabList, TabPanel, Tabs} from "react-tabs"
import "./App.scss"
import JTableDropContainer from "./JTableDropContainer"
import FlattedPane from "./FlattedPane"

const App = () => (
  <DndProvider backend={HTML5Backend}>
    <Tabs>
      <TabList>
        <Tab>JTable</Tab>
        <Tab>Flatted</Tab>
      </TabList>
      <TabPanel>
        <JTableDropContainer/>
      </TabPanel>
      <TabPanel>
        <FlattedPane/>
      </TabPanel>
    </Tabs>
  </DndProvider>
);

export default App;
