import React, { useState } from 'react';
import { Table as StrapTable } from 'reactstrap';
import styled from "styled-components";
import isObject from "lodash/isObject";
import isNil from "lodash/isNil";
import isArray from "lodash/isArray";
import Pagination from './Pagination';

const Title = styled.div`
  margin: 10px 0 10px 30px;
  font-size: x-large;
`;

const StyledTable = styled(StrapTable)`
  margin: 0 30px 0 30px;
`;

const StyledHeader = styled.th`
  position: sticky;
  position: -webkit-sticky;
  top: 10px;
  z-index: 10;
  background-color: #F564F5;
  color: white;
  padding: 10px;
  border-radius: 3%;
`;

const TableRow = styled.tr`
   &[data-selected] {
      background-color: #ffecd7a3;
    }
`;

const StyledCell = styled.td`
  padding: 10px;
  border: 1px solid #FFB5FD;
  border-radius: 3%;
  text-align: center;
`;

const FilterInput = styled.input`
  margin: 5px;
  padding: 5px;
  border-radius: 3%;
  border: 1px solid #FFB5FD;
`;

const LIMITED_ARRAY_SIZE = 10;
const LIMITED_STR_LENGTH = 100;

const formatCell = (item, limitLength = true) => {
    if (isNil(item)) {
        return "null";
    }
    if (isArray(item)) {
        if (limitLength && item.length > LIMITED_ARRAY_SIZE) {
            item = [item.slice(0, LIMITED_ARRAY_SIZE), "..."];
        }
        return `[${item.map(subItem => formatCell(subItem)).join(", ")}]`;
    }
    if (isObject(item)) {
        let keys = Object.keys(item);
        if (limitLength && keys.length > 10) {
            keys = keys.slice(0, 10);
        }
        return `{${keys.map(key => `${key}: ${formatCell(item[key])}`)}}`;
    }
    let itemStr = item.toString();
    if (limitLength && itemStr.length > LIMITED_STR_LENGTH) {
        itemStr = itemStr.substring(0, LIMITED_STR_LENGTH) + "...";
    }
    return itemStr;
};

const Table = ({ header, rows, fileName }) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [filters, setFilters] = useState(Array(header.length).fill(""));
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleFilterChange = (index, value) => {
        const newFilters = [...filters];
        newFilters[index] = value;
        setFilters(newFilters);
        setCurrentPage(0); // Reset to first page on filter change
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(Number(event.target.value));
        setCurrentPage(0); // Reset to first page on rows per page change
    };

    const filteredRows = rows.filter(row =>
        row.every((cell, index) =>
            formatCell(cell, false).toLowerCase().includes(filters[index].toLowerCase())
        )
    );

    const pageCount = Math.ceil(filteredRows.length / rowsPerPage);
    const offset = currentPage * rowsPerPage;
    const currentRows = filteredRows.slice(offset, offset + rowsPerPage);

    const handlePageClick = (page) => {
        setCurrentPage(page);
    };

    return (
        <React.Fragment>
            <Title>{fileName}</Title>
            <div style={{ margin: '0 30px 10px 30px', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    Show 
                    <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ margin: '0 10px' }}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                    </select>
                    rows per page
                </div>
                <div>
                    Showing {currentRows.length} of {filteredRows.length} rows
                </div>
            </div>
            <StyledTable>
                <thead>
                    <tr>
                        {header.map((column, index) => (
                            <StyledHeader key={column}>
                                {column}
                                <FilterInput
                                    type="text"
                                    value={filters[index]}
                                    onChange={(e) => handleFilterChange(index, e.target.value)}
                                    placeholder={`Filter ${column}`}
                                />
                            </StyledHeader>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentRows.map((row, index) => (
                        <TableRow
                            onClick={() => setSelectedRow(index)}
                            key={index}
                            data-selected={selectedRow === index ? true : null}
                        >
                            {row.map((item, subIndex) => (
                                <StyledCell
                                    key={`${index}.${subIndex}`}
                                    title={formatCell(item, false)}
                                >
                                    {formatCell(item)}
                                </StyledCell>
                            ))}
                        </TableRow>
                    ))}
                </tbody>
            </StyledTable>
            <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                onPageChange={handlePageClick}
            />
        </React.Fragment>
    );
};

export default Table;