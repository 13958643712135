import React, {useState} from 'react'
import styled from "styled-components"
import {parse} from 'flatted'

const StyledTextArea = styled.textarea`
  height: 500px;
  width: 500px;
`

const FlattedPane = () => {
  const [deflatted, setDeflatted] = useState("[]")
  let parsed
  try {
    parsed = parse(deflatted)
  } catch (e) {
    parsed = "Failed to parse as flatted"
  }

  return <div>
    <StyledTextArea
      placeholder="Paste flatted here"
      onChange={event => setDeflatted(event.target.value)}/>
    <pre>{JSON.stringify(parsed, null, 2)}</pre>
  </div>
}

export default FlattedPane