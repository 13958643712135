import React from 'react';
import styled from 'styled-components';

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 30px;
`;

const PaginationButton = styled.button`
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #FFB5FD;
  border-radius: 3%;
  background-color: ${props => (props.active ? '#F564F5' : 'white')};
  color: ${props => (props.active ? 'white' : '#F564F5')};
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const Pagination = ({ currentPage, pageCount, onPageChange }) => {
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5;
    const totalNumbers = maxPagesToShow + 2; // 5 pages + 2 for "..." indicators

    if (pageCount <= totalNumbers) {
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(i);
      }
    } else {
      let startPage = Math.max(2, currentPage - 2);
      let endPage = Math.min(pageCount - 1, currentPage + 2);

      if (currentPage <= 3) {
        startPage = 2;
        endPage = 5;
      }

      if (currentPage >= pageCount - 2) {
        startPage = pageCount - 4;
        endPage = pageCount - 1;
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (startPage > 2) {
        pageNumbers.unshift('...');
      }

      if (endPage < pageCount - 1) {
        pageNumbers.push('...');
      }

      pageNumbers.unshift(1);
      pageNumbers.push(pageCount);
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    <PaginationControls>
      <PaginationButton
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 0}
      >
        Previous
      </PaginationButton>
      {pageNumbers.map((number, index) => (
        <PaginationButton
          key={index}
          onClick={() => number !== '...' && onPageChange(number - 1)}
          active={currentPage === number - 1}
          disabled={number === '...'}
        >
          {number}
        </PaginationButton>
      ))}
      <PaginationButton
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === pageCount - 1}
      >
        Next
      </PaginationButton>
    </PaginationControls>
  );
};

export default Pagination;